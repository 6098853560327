<template>
<div class="premiumCalculation">
    <div class="box-welcome">
        <div class="text-greetings">
            <div class="welcome-text">{{ $t('motorVehicle.greetings4') }}</div>
        </div>
        <picture>
            <img src="../../../../public/images/motor-vehicle/Quotation-ask-1.webp" alt="Hello! Ready to save with Roojai?">
        </picture>
    </div>
    <div class="agreement-area">
        <button 
            class="btn btn-lg next2btn"
            @click="submitButton"
        >
            {{ $t('motorVehicle.calculatePremium') }}
        </button>
        <div class="text">
            {{ $t('motorVehicle.continueToNextstep') }}
        </div>
    </div>
</div>
</template>

<script >
import Tooltip from './Tooltip.vue';

export default {
    name: "PremiumCalculation",
    data() {
        return {
            textTooltip: "The car usage influences how you drive and helps us determine your exact premium.",
        };
    },
    components: { 
        Tooltip 
    },
    methods: {
        submitButton() {
            this.$emit('next');
        }
    }
}
</script>

<style scoped>
.premiumCalculation .box-welcome {
    max-width: 332px;
    margin: 0 auto;
    position: relative;
}
.premiumCalculation .box-welcome .text-greetings {
    position: absolute;
    top: 40px;
    width: 200px;
    height: auto;
    color: #003B71;
}
.premiumCalculation .box-welcome .text-greetings .welcome-head {
    width: 100%;
    text-align: center;
    font-size: 32px;
    line-height: 1.5;
    margin-left: 45px;
}
.premiumCalculation .box-welcome .text-greetings .welcome-text{
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    margin-left: 45px;
    margin-top: 10px;
}
.premiumCalculation .box-welcome picture img{
    width: 100%;
}
.premiumCalculation .agreement-area {
    margin: 24px auto;
}
.premiumCalculation .agreement-area .text {
    text-align: justify;
    text-align-last: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #003B71;
    margin-top: 24px;
}
.next2btn {
    color: #fff;
    border-radius: 5px;
    background-image: none;
    background-color: #ff5011;
    text-align: left;
    padding: 15px 21px;
    margin: 10px auto;
    box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
    font-size: 18px;
    width: 100%;
    text-align: center;
}
.next2btn:disabled {
    border: 1px solid #ccc!important;
    color: #ccc!important;
    background-color: #fff!important;
    box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}
</style>