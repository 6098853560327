<template>
    <div class="milage-components">
        <div class="title">
            <h1>{{ $t('motorVehicle.mileage') }}</h1>
            <div class="tooltip-title" v-if="textTooltip.length > 0">
                <Tooltip :text="textTooltip" />
            </div>
        </div>
        <div class="milage-image">
            <img src="../../../../public/images/motor-vehicle/Milage.svg" />
        </div>
        <div class="income-range">
            <h2>{{ odoText }}</h2>
            <div class="guide" v-if="valueRange == 0">
                <h2>{{ $t('motorVehicle.mileageinstruction1') }}</h2>
            </div>
            <input type="range" class="form-range" :max="this.odoMax" v-model="odoValue" :step="this.odoStep" id="slideRange">
            <h3 class="ad-title">{{ $t('motorVehicle.mileageOrange') }}</h3>
            <button
                v-if="showButton"
                class="btn btn-lg next2btn"
                :disabled="valueRange == 0"
                @click="setAnswer"

            >
                {{ $t("next") }} &#8595;
            </button>
        </div>
    </div>
</template>

<script>
import Tooltip from './Tooltip.vue';
import Mixin from "@/components/Mixin";

export default {
    name: "Mileage",
    mixins: [Mixin],
    props: {
        globalDataSaved: {
            type: [String, Number],
            default: 0
        },
        carYear: {
            type: Number,
            default: 0
        },
        odoStep: {
            type: Number,
            default: 0
        },
        odoMax: {
            type: Number,
            default: 0
        },
        resetMilage:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            valueRange: 0,
            odoValue: 0,
            valueText: "0 km",
            showButton: true
        }
    },
    components: { 
        Tooltip 
    },
    watch: {
        valueRange: function(value) {
            this.$emit('update', value);
        },
        odoStep: function(){
            this.odoValue = 0
            this.updateRange(0)
            this.updateGradient(0);
        }
    },
    mounted() {
        if (this.globalDataSaved) {
            this.showButton = false;
        }

        let slider = document.getElementById("slideRange");

        function updateGradient(rangeValue) {
            const percentage = (rangeValue - slider.min) / (slider.max - slider.min) * 100;
            slider.style.setProperty('--percentage', percentage + '%');
        }

        if(this.resetMilage){
            this.odoValue = 0;
        }

        if(this.globalDataSaved) {
            this.odoValue = this.globalDataSaved;
            slider.value = this.globalDataSaved;
            this.valueText = this.globalDataSaved;
        }

        updateGradient(slider.value);
        

        slider.addEventListener('input', (e) => {

            if( e.target.value > 0 ) {
                this.updateRange(e.target.value)
                updateGradient(e.target.value);
            }
            else{
                this.updateRange(0)
                updateGradient(0);
            }
        });
    },
    methods: {
        updateRange(value) {
            this.valueRange = value;
        },
        setAnswer() {
            this.showButton = false;
            this.$emit('next', this.valueRange);
            this.eventLog('odoMeterReading', this.valueRange)
        },
        updateGradient(rangeValue) {
            let slider = document.getElementById("slideRange");
            const percentage = (rangeValue - slider.min) / (slider.max - slider.min) * 100;
            slider.style.setProperty('--percentage', percentage + '%');
        }
    },
    computed: {
        textTooltip() {
            return "";
        },
        odoText(){
            if( this.odoValue > 0 ) {
                if( this.odoValue >= this.odoMax ){
                    var max = (this.odoValue - this.odoStep) + 1;
                    return  this.formatNumber(max) + " " + "Km or more";
                }else if(this.odoValue == this.odoStep){
                    return  "up to" + " " + this.formatNumber(this.odoValue) + " " + "Km";
                } else{
                    var start = (this.odoValue - this.odoStep) + 1;
                   return this.formatNumber(start) + ' - ' + this.formatNumber(this.odoValue) + " "+ "Km";
                }
            }else if(this.globalDataSaved){
                return this.globalDataSaved
            }
            else{
                return "0 km";
            }
        }
    }
}
</script>

<style scoped>
.milage-components h1 {
    font-size: 22px;
    text-align: center;
    width: 100%;
}
.milage-components .title {
    display: flex;
    position: relative;
}
.milage-components .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.milage-image {
    margin: 24px;
    text-align: center;
}

.form-range {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background-color: #d3d3d3;
  background-image: linear-gradient(90deg, #FF8462 var(--percentage), transparent var(--percentage));
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}


.form-range:hover {
  opacity: 1;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #FF4713;
  cursor: pointer;
}

.form-range::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #FF4713;
  cursor: pointer;
}

.income-range {
    text-align: center;
}
.income-range h2{
    text-align: center;
    font-size: 24px;
    color: #FF4713;
    margin-bottom: 30px;
    font-weight: 600;
}
.income-range button {
    margin-top: 30px;
}
.next2btn {
  color: #fff;
  border-radius: 5px;
  background-image: none;
  background-color: #ff5011;
  text-align: left;
  padding: 15px 21px;
  margin: 10px auto;
  box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
  font-size: 18px;
}
.next2btn:disabled {
  border: 1px solid #ccc!important;
  color: #ccc!important;
  background-color: #fff!important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}
.guide h2{
    font-size: 18px;
}
.ad-title {
    font-size: 16px;
    text-align: center;
    font-style: normal;
    margin-bottom: 24px;
    margin-top: 24px;
    font-weight: 400;
}
</style>