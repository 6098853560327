<template>
    <div class="new-milage">
        <div class="title">
            <h1>{{ $t('motorVehicle.mileage') }}</h1>
        </div>
        <div class="car-plate-image">
            <img src="../../../../public/images/motor-vehicle/Milage.svg" />
        </div>
        <div class="select-area">
            <ButtonSelect 
                :listsData="listsData" 
                :label="answerActive"
                @selected="selectData"
                :language="policyObject.root.quoteData.prefLang"
                optionsClass="carPlateOther"
                type="new-mileage"
                ref="newmileage"
            />
        </div>
        <div class="button-area">
            <button 
                v-if="showButtonNext"
                class="btn btn-lg next2btn"
                :disabled="answer == null"
                @click="nextToStep"
            >
                {{ $t('next') }} &#8595;
            </button>
        </div>
    </div>
    </template>
    <script>
    import ButtonSelect from './ButtonSelect.vue';
    import Tooltip from './Tooltip.vue';
    import Mixin from "@/components/Mixin";
    import Global from "@/store/global.js";
    import { policyObject } from "../data/PolicyObject";
    
    export default {
        name: "NewMilage",
        mixins: [Mixin],
        props: {
            listsData: {
                type: Array,
                default: () => []
            },
            defaultAnswer: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                Global,
                policyObject,
                answer: null,
                //textTooltip: "The car usage influences how you drive and helps us determine your exact premium.",
                showButtonNext: true,
                textAnswer: ""
            }
        },
        watch: {
            answer: function(value) {
                this.$emit("update", value);
            }
        },
        components: {
            Tooltip,
            ButtonSelect
        },
        methods: {
            selectData(value) {
                this.answer = value.value;
                Global.motorVehicle.textOdoV2 = { id: value.nameId, en: value.nameEn }; 
            },
            nextToStep() {
                this.showButtonNext = false;
                this.eventLog('odoMeterReading', this.answer)
                this.$emit("next", this.answer);
            },
            openDropdown(){
                this.$refs.newmileage.open();
            },
        },
        mounted() {
            if(this.defaultAnswer) {
                this.showButtonNext = false;
            }
        },
        computed: {
            answerActive() {
                if(this.defaultAnswer == null && this.answer == null) {
                    return this.$i18n.t('motorVehicle.newmileage');
                } else {
                    this.answer = this.defaultAnswer;
                    if (policyObject.root.quoteData.prefLang == 'id') {
                        return Global.motorVehicle.textOdoV2.id;
                    }else{
                        return Global.motorVehicle.textOdoV2.en;
                    }
                    
                }
            },
            textTooltip() {
                return this.$i18n.t('motorVehicle.tooltip4');
            }
        }
    }
    </script>
    <style scoped>
    .new-milage .title {
        display: flex;
        position: relative;
    }
    .new-milage .title h1{
        font-size: 22px;
        text-align: center;
        width: 96%;
    }
    .new-milage .title .tooltip-title {
        position: absolute;
        right: 0;
        top: -8px;
    }
    .new-milage .car-plate-image {
        text-align: center;
        margin: 24px;
    }
    .new-milage .button-area {
        text-align: center;
    }
    .next2btn {
      color: #fff;
      border-radius: 5px;
      background-image: none;
      background-color: #ff5011;
      text-align: left;
      padding: 15px 21px;
      margin: 10px auto;
      box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
      font-size: 18px;
    }
    .next2btn:disabled {
      border: 1px solid #ccc!important;
      color: #ccc!important;
      background-color: #fff!important;
      box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
    }
    @media screen and (max-width: 480px) { 
        .carPlateOther {
            max-height: 250px!important;
        }
    }
    
    @media screen and (max-width: 360px) { 
        .carPlateOther {
            max-height: 225px!important;
        }
    }
    
    </style>