import axios from "axios"
import Global from "@/store/global.js";

const domain = Global.roojaiService.url;
const baseURL = `${domain}`;

const axiosAPI = axios.create({
    baseURL,
    headers:{
        "Content-type": "application/json"
    }
});

export function carLists() {
    return axiosAPI.get("/vehicles?product=MotorCar");
}

export function carModel(payload) {
    return axiosAPI.get("/vehicles?product=MotorCar&brand=" + payload);
}

export function carProductionYear(payload) {
    return axiosAPI.get("/vehicles?product=MotorCar&brand="+ payload.brand +"&model=" + payload.model);
}

export function carSubModel(payload) {
    return axiosAPI.get("/vehicles?product=MotorCar&brand="+ payload.brand +"&model=" + payload.model +"&year="+ payload.year);
}

export function fullInformationCar(payload) {
    return axiosAPI.get("/vehicle-details?brand="+ payload.brand +"&model=" + payload.model +"&year="+ payload.year +"&subModel=" + payload.subModel);
}