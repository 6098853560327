<template>
    <div class="carSelection" :class="classDynamic">
        <div class="box-welcome">
            <div class="text-greetings">
                <div class="welcome-head">{{ (Global.isAgentCustomerView ? $t('motorVehicle.greetings1Agent') : $t('motorVehicle.greetings1') ) }}</div>
                <div class="welcome-text">{{ (Global.isAgentCustomerView ? $t('motorVehicle.greetings2Agent') : $t('motorVehicle.greetings2') ) }}</div>
            </div>
            <picture>
				<img src="../../../../public/images/motor-vehicle/Quotation-ask-1.webp" alt="Hello! Ready to save with Roojai?">
			</picture>
        </div>
        <h2 class="preheader">{{ $t('motorVehicle.greetings3') }}</h2>
        <ButtonSelect 
            :label="labelCarBrand"
            :listsData="Global.motorVehicle.carBrand"
            type="car-brand"
            @selected="selectCarBrand"
            :activeSelected="Global.motorVehicle.quote.Make"
            id="car-brand-input"
            @click="scrollToId('car-brand-input');"

        />
        <ButtonSelect 
            :label="labelCarModel"
            :listsData="Global.motorVehicle.carsModel"
            type="car-model"
            @selected="selectCarModel"
            id="car-model-input"
            ref="carmodel"
            v-if="Global.motorVehicle.selectedCarStep.carsModel"
            :showLoading="isLoading.carModelInput"
        />
        <ButtonSelect 
            :label="labelCarProductionYear"
            :listsData="Global.motorVehicle.carYear"
            @selected="selectProductionYear"
            v-if="Global.motorVehicle.selectedCarStep.carYear"
            ref="caryear"
            id="car-production-input"
            :showLoading="isLoading.productionYearInput"
            optionsClass="yearClass"
        />
        <ButtonSelect 
            :label="labelCarType"
            :listsData="Global.motorVehicle.quote.carSubmodel"
            @selected="selectCarSubModel"
            ref="cardescription"
            v-if="Global.motorVehicle.selectedCarStep.carSubmodel"
            id="car-submodel-input"
            :showLoading="isLoading.carSubModelInput"
            :activeSelected="Global.carSubModel" 
        />
    </div>
</template>
<script>
import ButtonSelect from './ButtonSelect.vue';
import Global from "@/store/global.js";
import Mixin from "@/components/Mixin";
import { carLists, carModel, carProductionYear, carSubModel } from "../lib/api";
  

export default {
    name: "CarSelections",
    data() {
        return {
            Global,
            carInput: {
                carBrandInput: null,
                carModelInput: null,
                productionYearInput: null,
                carSubModelInput: null
            },
            stateChanges: {
                carBrandInput: null,
                carModelInput: null,
                productionYearInput: null,
                carSubModelInput: null
            },
            loadingModel: false,
            isLoading: {
                carBrandInput: false,
                carModelInput: false,
                productionYearInput: false,
                carSubModelInput: false
            },
            classDynamic: "dynamicDefault"
        }
    },
    mixins: [Mixin],
    components: {
        ButtonSelect
    },
    watch: {
        "carInput.carBrandInput" : function(value){
            if(value !== this.stateChanges.carBrandInput) {
                this.$refs.carmodel.updateSelected(null);
                Global.motorVehicle.quote.carModel = null;
                this.stateChanges.carBrandInput = Global.motorVehicle.quote.Make;

                Global.motorVehicle.selectedCarStep.carsModel = true;
                Global.motorVehicle.selectedCarStep.carYear = false;
                Global.motorVehicle.selectedCarStep.carSubmodel = false;

                this.carInput.carModelInput = null;
                this.carInput.productionYearInput = null;
                this.carInput.carSubModelInput = null;
            
            } 
        },
        "carInput.carModelInput" : function(value) {
            if(value !== this.stateChanges.carModelInput) {
                this.stateChanges.carModelInput = Global.motorVehicle.quote.carModel;
                this.$refs.caryear.updateSelected(null);
                Global.motorVehicle.quote.Year_of_Manufacture = null;

                Global.motorVehicle.selectedCarStep.carYear = true;
                Global.motorVehicle.selectedCarStep.carSubmodel = false;

                this.carInput.productionYearInput = null;
                this.carInput.carSubModelInput = null;
                
            }
        },
        "carInput.productionYearInput" : function(value) {
            if(value !== this.stateChanges.productionYearInput) {
                this.$refs.cardescription.updateSelected(null);
                this.stateChanges.productionYearInput = Global.motorVehicle.quote.Year_of_Manufacture ;
                Global.motorVehicle.selectedCarStep.carSubmodel = true;

                this.$refs.cardescription.updateSelected(null);
                Global.motorVehicle.quote.Model_Description = null;

                this.carInput.carSubModelInput = null;
            }
        },
        "carInput.carSubModelInput" : function(value) {
            if(value !== this.stateChanges.carSubModelInput) {
            }
        }
    },
    mounted() {
        if(Global.motorVehicle.quote.Make !== null) {
            this.carInput.carBrandInput = Global.motorVehicle.quote.Make;
            this.stateChanges.carBrandInput = Global.motorVehicle.quote.Make;
        }

        if(Global.motorVehicle.quote.carModel !== null) {
            this.carInput.carModelInput = Global.motorVehicle.quote.carModel;
            this.stateChanges.carModelInput = Global.motorVehicle.quote.carModel;
        }

        if(Global.motorVehicle.quote.Year_of_Manufacture !== null) {
            this.carInput.productionYearInput = Global.motorVehicle.quote.Year_of_Manufacture;
            this.stateChanges.productionYearInput = Global.motorVehicle.quote.Year_of_Manufacture;
        }

        if(Global.motorVehicle.quote.Model_Description !== null) {
            this.carInput.carSubModelInput = Global.motorVehicle.quote.Year_of_Manufacture;
            this.stateChanges.carSubModelInput = Global.motorVehicle.quote.Year_of_Manufacture;
        }

        this.fetchCarLists();
    },
    methods: {
        async fetchCarLists() {
            try {
              const response = await carLists();
              Global.motorVehicle.carBrand = response.data.data;
            } catch(error) {
              console.log(error);
            }
          },
  
          async fetchCarModel() {
                this.isLoading.carModelInput = true;
              try {
                const response = await carModel(Global.motorVehicle.quote.Make);
                Global.motorVehicle.carsModel = response.data;
                this.isLoading.carModelInput = false;
                this.$refs.carmodel.open();
                this.classDynamic = "dynamicHiCarModel";
              } catch(error) {
                console.log(error);
                this.isLoading.carModelInput = false;
              }
            
          },
  
          async fetchCarProductionYear() {
            this.isLoading.productionYearInput = true;
            const payload = {
              brand: Global.motorVehicle.quote.Make,
              model: Global.motorVehicle.quote.carModel
            };
            try {
              const response = await carProductionYear(payload);
              Global.motorVehicle.carYear = response.data.data;
              this.isLoading.productionYearInput = false;
              this.$refs.caryear.open();
              this.classDynamic = "dynamicHiCarYear";
            } catch(error) {
                this.isLoading.productionYearInput = false;
                console.log(error);
            }
          },
  
          async fetchCarSubmodel() {
            this.isLoading.carSubModelInput = true;
            const payload = {
                brand: Global.motorVehicle.quote.Make,
                model: Global.motorVehicle.quote.carModel,
                year: Global.motorVehicle.quote.Year_of_Manufacture
            };
            try {
              const response = await carSubModel(payload);
              Global.motorVehicle.quote.carSubmodel = response.data.data;
              this.isLoading.carSubModelInput = false;
              this.$refs.cardescription.open();
              this.classDynamic = "dynamicHiCarDesc";
            } catch(error) {
                this.isLoading.carSubModelInput = false;
              console.log(error);
            }
          },
  
        selectCarBrand(value) {
            if(this.carInput.carBrandInput == null) {
                this.stateChanges.carBrandInput = value;
            } 
            Global.motorVehicle.quote.Make = value;
            this.carInput.carBrandInput = value;
            this.pushStep('car-brand');
            this.fetchCarModel();
            this.$emit("selected", this.carInput);
            this.eventLog('Make', value);
            this.scrollToId("car-model-input");

            Global.motorVehicle.selectedCarStep.carsModel = true;
            
        },
        selectCarModel(value) {
            if(this.carInput.carModelInput == null) {
                this.stateChanges.carModelInput = value;
            } 

            this.carInput.carModelInput = value;
            Global.motorVehicle.quote.carModel = value;
            this.fetchCarProductionYear();
            this.pushStep('car-model');
            this.$emit("selected", this.carInput);
            this.eventLog('Model_Family', value);
            this.scrollToId("car-production-input");
    
            Global.motorVehicle.selectedCarStep.carYear = true;
        },
        selectProductionYear(value) {
            if(this.carInput.productionYearInput == null) {
                this.stateChanges.productionYearInput = value;
            }
            Global.motorVehicle.quote.Year_of_Manufacture = value;
            this.carInput.productionYearInput = value;
            this.fetchCarSubmodel();
            this.pushStep('car-year');
            this.$emit("selected", this.carInput);
            this.eventLog('Year_of_Manufactur', value);
            this.scrollToId("car-submodel-input");
            
            Global.motorVehicle.selectedCarStep.carSubmodel = true;
        },
        selectCarSubModel(value) {
            if(this.carInput.carSubModelInput == null) {
                this.stateChanges.carSubModelInput = value;
            } 
            this.carInput.carSubModelInput = value;
            Global.carSubModel = value;
            this.pushStep('car-subModel');
            this.$emit("selected", this.carInput);
            this.eventLog('FamilyCode', value);
            this.classDynamic = "dynamicDisapear";
        },
        scrollToId(element) {
            if (element === "car-brand-input") {
                this.classDynamic = 'firstOpen';
            }
            setTimeout(()=> {
                // document.getElementById(element).scrollIntoView({
                //     behavior: "smooth",
                //     top: 50
                // }, true);
                const elmnt = document.getElementById(element);

                const elementPosition = elmnt .getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: elementPosition - 20,
                    behavior: 'smooth' 
                });
            });
        },
    },
    computed: {
        labelCarBrand() {
            if(Global.motorVehicle.quote.Make) {
                return Global.motorVehicle.quote.Make;
            } else {
                return this.$i18n.t('motorVehicle.carBrand')
            }
        },
        labelCarModel() {
            if(Global.motorVehicle.quote.carModel) {
                return Global.motorVehicle.quote.carModel;
            } else {
                return this.$i18n.t('motorVehicle.carModel');
            }
        },
        labelCarProductionYear() {
            if(Global.motorVehicle.quote.Year_of_Manufacture) {
                return String(Global.motorVehicle.quote.Year_of_Manufacture);
            } else {
                return this.$i18n.t('motorVehicle.carProductionYear');
            }
        },
        labelCarType() {
            if(Global.motorVehicle.quote.Model_Description) {
                return Global.motorVehicle.quote.Model_Description;
            } else {
                return this.$i18n.t('motorVehicle.carType');
            }
        }
    }
}
</script>
<style scoped>
.carSelection .box-welcome {
    max-width: 332px;
    margin: 0 auto;
    position: relative;
}
.carSelection .box-welcome .text-greetings {
    position: absolute;
    top: 40px;
    width: 220px;
    height: auto;
    color: #003B71;
}
.carSelection .box-welcome .text-greetings .welcome-head {
    width: 100%;
    text-align: center;
    font-size: 24px;
    line-height: 28px;
    font-weight: 800;
    margin-left: 35px;
}
.carSelection .box-welcome .text-greetings .welcome-text{
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    margin-left: 35px;
}
.carSelection .box-welcome picture img{
    width: 100%;
}
.carSelection .preheader {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    color: #003B71;
    margin-top: 15px;
}
.firstOpen {
    height: 100vh!important;
}
.dynamicDefault {
    height: 100vh!important;
}
.dynamicHiCarModel {
    height: 100vh!important;
}
.dynamicHiCarYear {
    height: 100vh!important;
}
.dynamicHiCarDesc {
    height: 120vh!important;
}
.dynamicDisapear {
    height: auto!important;
}
@media screen and (max-width: 480px) {
    .dynamicHiCarModel {
        height: 100vh!important;
    }
    .dynamicHiCarYear {
        height: 150vh!important;
    }
    .dynamicHiCarDesc {
        height: 150vh!important;
    }
    .dynamicDisapear {
        height: auto!important;
    }
}
@media screen and (max-width: 379px) {
    .carSelection .box-welcome .text-greetings {
        top: 35px !important;
        width: 200px !important;
    }
    .carSelection .box-welcome .text-greetings .welcome-head {
        margin-left: 32px!important;
        font-size: 22px !important;
    }
    .carSelection .box-welcome .text-greetings .welcome-text {
        margin-left: 32px!important;
        font-size: 12px !important;
    }
    
}
</style>