<template>
<div class="drivingLicense">
    <div class="title">
        <h1>{{ $t('motorVehicle.drivingLicense') }}</h1>
        <div class="tooltip-title">
            <Tooltip :text="textTooltip" />
        </div>
    </div>
    <div class="dl-image">
        <img src="../../../../public/images/motor-vehicle/DrivingLicense.svg" />
    </div>
    <div class="row row-btn">
        <div 
            :class="['col', index == 0 ? 'col-12' : 'col-6']"  
            v-for="(item, index) in optionQuestions" 
            :key="index"
        >
            <button 
                class="btn btn-answer btn-block" 
                :class="{btnActive: answerActive == item.id}"
                @click="setAnswer(item.id)"
            >
                {{ item.text }}
            </button>
        </div>
    </div>
</div>
</template>

<script>
import Tooltip from './Tooltip.vue';
import Mixin from "@/components/Mixin";

export default {
    name: "DrivingLicenseQuestion",
    mixins: [Mixin],
    props: {
        defaultAnswer: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            answer: null,
            questions: [
                { id: 0, text: "Kurang dari 1 tahun" },
                { id: 1, text: "1" },
                { id: 2, text: "2" },
                { id: 3, text: "3" },
                { id: 4, text: "4" },
                { id: 5, text: "5" },
                { id: 6, text: "6 atau lebih" }
            ],
        };
    },
    components: { 
        Tooltip 
    },
    methods: {
        setAnswer(value) {
            this.answer = value;
            this.eventLog('DrivingLicense', this.answer === 6 ? "6+" : String(this.answer));
            this.$emit("next", this.answer);
        }
    },
    computed: {
        optionQuestions() {
            return [
                { id: 0, text: this.$i18n.t('motorVehicle.answer.lessThan1Year') },
                { id: 1, text: "1" },
                { id: 2, text: "2" },
                { id: 3, text: "3" },
                { id: 4, text: "4" },
                { id: 5, text: "5" },
                { id: 6, text: this.$i18n.t('motorVehicle.answer.sixOrMore') }
            ];
        },
        answerActive() {
            if(this.defaultAnswer == null) {
                return this.answer
            } else {
                this.answer = this.defaultAnswer === "6+" ? "6" : this.defaultAnswer;
                return this.defaultAnswer === "6+" ? "6" : this.defaultAnswer;
            }
        },
        textTooltip() {
            return this.$i18n.t('motorVehicle.tooltipDriving');
        }
    }
}
</script>

<style scoped>
.drivingLicense {
    position: relative;
}
.drivingLicense .title {
    display: flex;
    position: relative;
}
.drivingLicense .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.drivingLicense h1{
    font-size: 22px;
    text-align: center;
    width: 100%;
}
.dl-image {
    margin: 24px;
    text-align: center;
}

.row-btn {
    margin-top: 50px;
}
.row-btn .col {
    padding-right: 8px!important;
    padding-left: 8px!important;
}
.btn-answer {
    background: #FFFFFF;
    border: 1px solid #EF4A24;
    border-radius: 8px;
    padding: 15px 21px;
    color: #EF4A24;
    font-size: 20px;
}
.btnActive {
    background: #0E5DA7;
    border: 1px solid #0E5DA7;
    color: #ffffff;
}
.row-btn button:first-child {
    margin-bottom: 15px;
}
@media screen and (max-width: 480px) {
    .drivingLicense h1{
        width: 97%;
    }
    .row-btn button:first-child {
        margin-bottom: 15px;
    }
    .drivingLicense .title .tooltip-title {
        right: -10px;
    }
}
</style>