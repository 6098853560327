<template>
<div class="claimQuestion">
    <div class="title">
        <h1>{{ $t('motorVehicle.claimQuestion') }}</h1>
        <div class="tooltip-title">
            <Tooltip :text="textTooltip" />
        </div>
    </div>
    <div class="claim-image">
        <img src="../../../../public/images/motor-vehicle/ClaimQuestion.svg" />
    </div>
    <div class="row row-btn">
        <div class="col col-6" v-for="(item, index) in answerChoices" :key="index">
            <button 
                class="btn btn-answer btn-block"
                :class="{btnActive: answerActive == item.id}"
                @click="setAnswer(item.id);"
            >
                {{ item.text }}
            </button>
        </div>
    </div>
</div>
</template>
<script>
import Tooltip from './Tooltip.vue';
import Mixin from "@/components/Mixin";

export default {
    name: "ClaimQuestion",
    mixins: [Mixin],
    data() {
        return {
            //textTooltip: "The car usage influences how you drive and helps us determine your exact premium.",
            answer: null
        };
    },
    props: {
        defaultAnswer: {
            type: Number,
            default: null
        }
    },
    components: { 
        Tooltip 
    },
    methods: {
        setAnswer(value) {
            this.answer = value;
            this.$emit("next", this.answer);
            this.eventLog('driverAccidents', value)           
        },
    },
    computed: {
        answerChoices() {
            return [
                {
                    id: 0,
                    text: "0",
                },
                {
                    id: 1,
                    text: "1",
                },
                {
                    id: 2,
                    text: "2",
                },
                {
                    id: 3,
                    text: this.$i18n.t('motorVehicle.answer.threeOrMore'),
                }
            ]
        },
        answerActive() {
            if(this.defaultAnswer == null) {
                return this.answer
            } else {
                this.answer = this.defaultAnswer;
                return this.defaultAnswer;
            }
        },
        textTooltip() {
            return this.$i18n.t('motorVehicle.tooltipclaim');
        }
    }
}
</script>
<style scoped>
.claimQuestion .title {
    display: flex;
    position: relative;
}
.claimQuestion .title h1{
    font-size: 22px;
    text-align: center;
    width: 96%;
}
.claimQuestion .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.claimQuestion .claim-image {
    text-align: center;
    margin: 24px;
}

.row-btn {
    margin-top: 50px;
}
.row-btn .col {
    padding-right: 8px!important;
    padding-left: 8px!important;
}
.btn-answer {
    background: #FFFFFF;
    border: 1px solid #EF4A24;
    border-radius: 8px;
    padding: 15px 21px;
    color: #EF4A24;
    font-size: 20px;
}
.btnActive {
    background: #0E5DA7;
    border: 1px solid #0E5DA7;
    color: #ffffff;
}
.row-btn button:first-child {
    margin-bottom: 15px;
}
@media screen and (max-width: 480px) {
    .row-btn button:first-child {
        margin-bottom: 15px;
    }
}
</style>