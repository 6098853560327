<template>
<div class="carForWork">
    <div class="title">
        <h1>{{ $t('motorVehicle.carForWork') }}</h1>
        <div class="tooltip-title">
            <Tooltip :text="textTooltip" />
        </div>
    </div>
    <div class="row row-btn">
        <div class="col-12">
            <button 
                class="btn btn-answer btn-block" 
                :class="{btnActive: answerActive === 'SPTC'}"
                @click="setAnswer('SPTC');eventLog('Vehicle_Usage', 'Driving to and back from work')"
            >
                {{ $t('motorVehicle.answer.yes') }}
            </button>
        </div>
        <div class="col-12">
            <button 
                class="btn btn-answer btn-block" 
                @click="setAnswer('SPT');eventLog('Vehicle_Usage', 'Social, pleasure and travelling')"
                :class="{btnActive: answerActive === 'SPT'}"
            >
                {{ $t('motorVehicle.answer.no') }}
            </button>
        </div>
    </div>
</div>
</template>
<script>
import Tooltip from './Tooltip.vue';
import Mixin from "@/components/Mixin";

export default {
    name: "CarForWork",
    mixins: [Mixin],
    data() {
        return {
            answer: null,
            //textTooltip: "The car usage influences how you drive and helps us determine your exact premium." 
        }
    },
    props: {
        defaultAnswer: {
            type: String,
            default: null
        }
    },
    components: { 
        Tooltip 
    },
    methods: {
        setAnswer(value) {
            this.answer = value;
            this.$emit("next", this.answer);
        }
    },
    computed: {
        answerActive() {
            if(this.defaultAnswer == null) {
                return this.answer
            } else {
                this.answer = this.defaultAnswer;
                return this.defaultAnswer;
            }
        },
        textTooltip() {
            return this.$i18n.t('motorVehicle.tooltip3');
        }
    }
}
</script>
<style scoped>
.carForWork {
    position: relative;
}
.carForWork .title {
    display: flex;
    position: relative;
}
.carForWork .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.carForWork .title h1{
    font-size: 22px;
    text-align: center;
    width: 96%;
}

.row-btn {
    margin-top: 50px;
}
.btn-answer {
    background: #FFFFFF;
    border: 1px solid #EF4A24;
    border-radius: 8px;
    padding: 15px 21px;
    color: #EF4A24;
    font-size: 20px;
}
.btnActive {
    background: #0E5DA7;
    border: 1px solid #0E5DA7;
    color: #ffffff;
}
.row-btn button:first-child {
    margin-bottom: 15px;
}
@media screen and (max-width: 480px) {
    .row-btn button:first-child {
        margin-bottom: 15px;
    }
}
</style>