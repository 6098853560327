<template>
<div class="carPlate">
    <div class="title">
        <h1>{{ $t('motorVehicle.carPlate') }}</h1>
        <div class="tooltip-title">
            <Tooltip :text="textTooltip"/>
        </div>
    </div>
    <div class="car-plate-image">
        <img src="../../../../public/images/motor-vehicle/CarPlate.svg" />
    </div>
    <div class="select-area">
        <ButtonSelect 
            :listsData="listsData" 
            :label="answerActive"
            @selected="selectData"
            optionsClass="carPlateOther"
            ref="carplatearea"
        />
    </div>
    <div class="button-area">
        <button 
            v-if="showButtonNext"
            class="btn btn-lg next2btn"
            :disabled="answer == null"
            @click="nextToStep"
        >
            {{ $t('next') }} &#8595;
        </button>
    </div>
</div>
</template>
<script>
import ButtonSelect from './ButtonSelect.vue';
import Tooltip from './Tooltip.vue';
import Mixin from "@/components/Mixin";

export default {
    name: "CarPlate",
    mixins: [Mixin],
    props: {
        listsData: {
            type: Array,
            default: () => []
        },
        defaultAnswer: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            answer: null,
            //textTooltip: "The car usage influences how you drive and helps us determine your exact premium.",
            showButtonNext: true
        }
    },
    watch: {
        answer: function(value) {
            this.$emit("update", value);
        }
    },
    components: {
        Tooltip,
        ButtonSelect
    },
    methods: {
        selectData(value) {
            this.answer = value;
        },
        nextToStep() {
            this.showButtonNext = false;
            this.eventLog('CarPlateArea', this.answer)
            this.$emit("next", this.answer);
        },
        openDropdown(){
            this.$refs.carplatearea.open();
        },
    },
    mounted() {
        if(this.defaultAnswer) {
            this.showButtonNext = false;
        }
    },
    computed: {
        answerActive() {
            if(this.defaultAnswer == null && this.answer == null) {
                return this.$i18n.t('motorVehicle.choosePlateArea');
            } else {
                this.answer = this.defaultAnswer;
                return this.defaultAnswer;
            }
        },
        textTooltip() {
            return this.$i18n.t('motorVehicle.tooltip4');
        }
    }
}
</script>
<style scoped>
.carPlate .title {
    display: flex;
    position: relative;
}
.carPlate .title h1{
    font-size: 22px;
    text-align: center;
    width: 96%;
}
.carPlate .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.carPlate .car-plate-image {
    text-align: center;
    margin: 24px;
}
.carPlate .button-area {
    text-align: center;
}
.next2btn {
  color: #fff;
  border-radius: 5px;
  background-image: none;
  background-color: #ff5011;
  text-align: left;
  padding: 15px 21px;
  margin: 10px auto;
  box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
  font-size: 18px;
}
.next2btn:disabled {
  border: 1px solid #ccc!important;
  color: #ccc!important;
  background-color: #fff!important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}
@media screen and (max-width: 480px) { 
    .carPlateOther {
        max-height: 250px!important;
    }
}

@media screen and (max-width: 360px) { 
    .carPlateOther {
        max-height: 225px!important;
    }
}

</style>