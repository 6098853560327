const carPlate = [
    "G",
    "H",
    "K",
    "R",
    "AA",
    "AD",
    "AB",
    "D",
    "E",
    "F",
    "T",
    "Z",
    "A",
    "B",
    "L",
    "M",
    "N",
    "P",
    "S",
    "W",
    "AE",
    "AG",
    "DK",
    "DR",
    "EA",
    "DH",
    "EB",
    "ED",
    "KB",
    "DA",
    "KH",
    "KT",
    "KU",
    "DB",
    "DL",
    "DM",
    "DP",
    "DN",
    "DT",
    "DD",
    "DC",
    "PA",
    "PB",
    "DE",
    "DG",
    "BL",
    "BB",
    "BK",
    "BA",
    "BM",
    "BH",
    "BD",
    "BP",
    "BG",
    "BN",
    "BE",
];

export default carPlate.sort();

