<template>
    <div class="dash-components">
        <h3>{{$t('motorVehicle.carRecognize', {car: carBrand+' '+carType} )}}!</h3>
        <div class="title">
            <h1>{{ $t('motorVehicle.dashCam') }}</h1>
            <div class="tooltip-title">
                <Tooltip :text="textTooltip" />
            </div>
        </div>
        <div class="dash-image">
            <img src="../../../../public/images/motor-vehicle/Dash_camera.svg" />
        </div>
        <div class="row row-btn">
            <div class="col-12">
                <button 
                    class="btn btn-answer btn-block" 
                    :class="{btnActive: dashAnswer === 'Yes' || globalDataSelected === 'Yes'}"
                    @click="setAnswer('Yes');"
                >
                    {{ $t('motorVehicle.answer.yes') }}
                </button>
            </div>
            <div class="col-12">
                <button 
                    class="btn btn-answer btn-block" 
                    @click="setAnswer('No');"
                    :class="{btnActive: dashAnswer === '0' || globalDataSelected === 'No'}"
                >
                {{ $t('motorVehicle.answer.no') }}
                </button>
            </div>
        </div>
        <h3 class="ad-title">{{ $t('motorVehicle.dashNotes') }}</h3>
    </div>
</template>
<script>
import Tooltip from "./Tooltip.vue";
import Mixin from "@/components/Mixin";

export default {
    name: "DashCamera",
    mixins: [Mixin],
    props: {
        carType: {
            type: String,
            default: ""
        },
        carBrand: {
            type: String,
            default: ""
        },
        globalDataSelected: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            dashAnswer: null,
        };
    },
  components: {
    Tooltip
    },
    methods: {
      setAnswer(value) {
        this.dashAnswer = value;
        this.$emit("next", this.dashAnswer);
      }
    },
    computed: {
        textTooltip() {
            return this.$i18n.t('motorVehicle.tooltip1');
        }
    }
};
</script>
<style scoped>
.dash-components {
    position: relative;
}
.dash-components .title {
    display: flex;
    position: relative;
}
.dash-components .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.dash-components h3{
    font-size: 16px;
    text-align: center;
    font-style:normal;
    margin-bottom: 24px;
    font-weight: 400;
}
.dash-components h1{
    font-size: 22px;
    text-align: center;
    width: 100%;
}
.ad-title {
    margin-top: 24px;
}
.dash-image {
    margin: 24px;
    text-align: center;
}
.row-btn {
    margin-top: 50px;
}
.btn-answer {
    background: #FFFFFF;
    border: 1px solid #EF4A24;
    border-radius: 8px;
    padding: 15px 21px;
    color: #EF4A24;
    font-size: 20px;
}
.btnActive {
    background: #0E5DA7;
    border: 1px solid #0E5DA7;
    color: #ffffff;
}
.row-btn button:first-child {
    margin-bottom: 15px;
}
@media screen and (max-width: 480px) {
    .row-btn button:first-child {
        margin-bottom: 15px;
    }
    .dash-components h1{
        width: 92%;
    }
}
</style>